/*body {*/
/*    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
/*    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
/*    sans-serif;*/
/*}*/

.input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.label,
section > label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: rgba(21, 21, 21, 0.91);
    font-size: 14px;
    font-weight: 200;
}

.button {
    background: #ec5990;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
}

.buttonBlack {
    background: black;
    border: 1px solid white;
}

body {
    margin: 0;
}

.rbc-event-label {
    display: none;
}

.touchableOpacity {
    opacity: 1;
    transition: opacity 300ms ease;
}

.touchableOpacityTouched {
    opacity: 0.5;
}

:focus {outline:none;}
/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./fonts/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./fonts/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}


/* Colonel */
@font-face {
    font-family: 'Colonel';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/Colonel-Lowercase-205TF.ttf') format('truetype'); /* Safari, Android, iOS */
}
